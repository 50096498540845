import InlineAudioPlayer from "./InlineAudioPlayer/InlineAudioPlayer";
import classNames from "classnames";
import "./App.css";

// const songbirdCloudfront = (environment, id, counterName) => {
//   if (environment && environment === "test") {
//     // test locations
//     if (counterName && counterName.indexOf(".preview.") !== -1) {
//       return `https://d3r7nkke29tjjo.cloudfront.net/audio-assets/preview/${id}.mp3`;
//     }
//     return `https://d3r7nkke29tjjo.cloudfront.net/audio-assets/${id}.mp3`;
//   }
//   // live locations
//   if (counterName && counterName.indexOf(".preview.") !== -1) {
//     return `https://d100cfb0bl1j19.cloudfront.net/audio-assets/preview/${id}.mp3`;
//   }
//   return `https://d100cfb0bl1j19.cloudfront.net/audio-assets/${id}.mp3`;
// };

function App() {
  const songBirdClasses = classNames({
    "inline-audio": true,
    "article__author-unit": true,
    "inline-audio__visible": true,
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const vpid = urlParams.get("vpid");
  const cPid = urlParams.get("id");

  const articleTitle = `Flamingo Player - ${vpid}`;

  const atiTrackingAttributes = {
    "data-bbc-container": "flamingo",
    "data-bbc-title": articleTitle,
    "data-bbc-metadata": `{"APP":"flamingo","CHD":"card::1"}`,
    "data-bbc-result": "",
    "data-bbc-client-routed": true,
  };

  return (
    <div className={songBirdClasses}>
      <InlineAudioPlayer
        // href={songbirdCloudfront(
        //   "test",
        //   "20140606-how-do-you-weigh-a-planet",
        //   false
        // )}
        audioLabel={articleTitle}
        cPid={cPid}
        vPid={vpid}
        counterName={`flamingo.${vpid}.page`}
        disableAdverts={true}
        guidance=""
        mobile={false}
        externalPlugin="https://flamingo.voice.tools.bbc.co.uk/flamingo/audioPlugin.js"
        statsDestination="WS_NEWS_LANGUAGES"
        statsProducer="FLAMINGO"
        atiTrackingAttributes={atiTrackingAttributes}
        branding="flamingo"
      />
    </div>
  );
}

export default App;
